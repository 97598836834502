import React, {Component, Fragment} from 'react';
import {ErrorRow, ErrorSearch} from "./error_search";
import JWT from "../jwt_refresh";
import axios from "axios";
import PlanForm from "./plan_form";
import moment from "moment";
import {BootstrapText, Button} from "alpaca.js/dist/autoform/bootstrap";
import {Col, Row} from "reactstrap";

export default class ErrorFix extends Component {
    constructor(props) {
        super(props);
        console.log("Props in ErrorFix", props)
        this.state = {
            data: [],
            pagination: {},
            form_title: "",
            registration: null,
            response: null,
            eid: null,
            last_query: "",
            checked: [],
            toDelete: [],
            initial: {
                error_date: moment().subtract(7, 'days').format("YYYY-MM-DD"),
                error_date2: moment().format("YYYY-MM-DD")
            },
            current_page: 1,
            per_page: 10
        };
        // make auto refreshed token object
        this.jwt = new JWT({access_token: props.access_token, refresh_token: props.refresh_token});
        this.byDate = this.byDate.bind(this);
        this.byMessageId = this.byMessageId.bind(this);
        this.removeRegistration = this.removeRegistration.bind(this);
        this.addRegistration = this.addRegistration.bind(this);
        this.updateDates = this.updateDates.bind(this);
        this.updatePage = this.updatePage.bind(this);
        this.clearError = this.clearError.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.uncheckAll = this.uncheckAll.bind(this);

    }

    getData(url) {
        this.setState({loading: true});
        axios.get(url, {headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}}).then((response) => {
            if (response.data.detail) {
                this.setState({
                    form_title: response.data.title,
                    data: response.data.detail,
                    pagination: response.data.pagination,
                });
                console.log("url", url);
                console.log("pagination", response.data.pagination);
                console.log("data", response.data.detail);
            } else {
                this.setState({
                    form_title: "No Errors Found",
                    current_page: 1,
                    data: [],
                    pagination: {}
                });
            }
            this.setState({loading: false});
        }).catch((error)=> {
            this.setState({loading: false});
            if(response.data){
                this.setState({form_title: error.response.data.title});
            }

        });
        // ).catch((error) => {
        //     this.setState({form_title: error.response.data.title});
        //     this.handleErrors(error.response.data.errors);
        // })
    }
    updateDates(formState){
        // save the user's search dates
        this.setState({
            initial: {
                error_date: formState.values.error_date,
                error_date2: formState.values.error_date2
            },
            current_page: 1
        });
    }

    updatePage(data){
        let page = (0 + data.selected) + 1;
        console.log("New Page", page)
        if (page < 1) {
            page = 1;
        }
        if (page > this.state.pagination.count_pages) {
            page = this.state.pagination.count_pages;
        }
        this.setState({current_page: page}, function() {
            console.log("Callback...")
            this.byDate({}, this.state.initial.error_date, this.state.initial.error_date2)
        }.bind(this) );
    }

    byDate(e, from_date, to_date) {
        let dates = {};
        let querystring;
        if (from_date) {
            dates["from_date"] = from_date;
        }
        if (to_date) {
            dates['to_date'] = to_date;
        }
        dates["page"] = this.state.current_page;
        dates["per_page"] = this.state.per_page;
        querystring = Object.keys(dates).map(key => key + '=' + dates[key]).join('&');
        querystring = `${this.props.errors_url}?${querystring}`;
        this.setState({"last_query": querystring, "toDelete": [] });
        this.getData(querystring);
    }

    byMessageId(e, message_id) {
        // console.log("byMessageId", message_id);
    }

    removeRegistration(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({registration: null});
        if (this.state.last_query){
            this.getData(this.state.last_query)
        }
    }

    clearError(eid) {
        axios.post(this.props.clear_url, [eid], {
            headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}
        }).then((response) => {
            this.setState({eid: null});
            window.location.reload();
        }).catch((error) => {
            let errorMsg = "";
            if (!error.response) {
                errorMsg = JSON.stringify(error);
            } else {
                let errors = error.response.data.errors;
                console.log(JSON.stringify(errors));

                let keys = Object.keys(errors);
                keys.forEach((key, index) => {
                    errorMsg = errorMsg + `${key}: ${errors[key]}`
                })
            }
        });
    }
    uncheckAll(refs){
        for(let ref in refs){
            console.log("The elelment is", ref)
        }
    }
    clearErrors(errors) {
        if (errors.length) {
            axios.post(this.props.clear_url, errors, {
                headers: {Authorization: `Bearer ${this.jwt.get_access_token()}`}
            }).then((response) => {
                // console.log("Data ", this);
                // let inputs = this.state.checked;
                // let inputs = document.getElementsByClassName("form-check-input")
                window.location.reload();

                for(let input in inputs){
                    inputs[input].checked = false;
                }de
                this.setState({
                    toDelete: [],
                    checked: []
                })
                this.byDate({}, this.state.initial.error_date, this.state.initial.error_date2)
            }).catch((error) => {
                let errorMsg = "";
                if (!error.response) {
                    errorMsg = JSON.stringify(error);
                } else {
                    let errorlist = error.response.data.errors;
                    console.log(JSON.stringify(errorlist));

                    let keys = Object.keys(errorlist);
                    keys.forEach((key, index) => {
                        errorMsg = errorMsg + `${key}: ${errorlist[key]}`
                    })
                }
            });
        }
    }

    addRegistration(registration, response, id) {
        // we should use the current data they are logged in with, it's possible that it changed in the crm.
        registration["distributor_number"] = this.props.registration["distributor_number"];
        registration["retailer_number"] = this.props.registration["retailer_number"];
        this.setState({
                registration: registration,
                response: response,
                eid: id,
            }
        );
    }

    render() {
        let plan_reg_url = this.props.plan_reg_url;
        let lookup_url = this.props.lookup_url;
        let registration = this.state.registration;
        console.log("Registration=", this.state.registration);
        let response = this.state.response;
        return (
            // either show the error search form or the plan edit form.
            <Fragment>
                {!registration &&
                <ErrorSearch title={this.state.form_title}
                             data={this.state.data}
                             pagination={this.state.pagination}
                             byDate={this.byDate}
                             byMessageId={this.byMessageId}
                             addRegistration={this.addRegistration}
                             initial={this.state.initial}
                             updateDates={this.updateDates}
                             updatePage={this.updatePage}
                             errorFix={this}
                             current_page={this.state.current_page}
                             loading={this.state.loading}
                />
                }
                {registration &&
                <Fragment>
                    <Row>
                        <Col className={"col-sm-10"}>
                            <a href="/plans/registration_errors/" className="back-link"
                               onClick={this.removeRegistration}>
                                Back to Errors
                            </a>
                        </Col>
                        <Col className={"col-sm-2"}>
                            <button className={"btn btn-secondary text-danger"} onClick={() => {
                                console.log("This in onClick on Clear Error", this);
                                this.clearError(this.state.eid);
                            }}>
                                ❌ Clear Error
                            </button>
                        </Col>
                    </Row>

                    <PlanForm
                        active_tab={1}
                        registration={registration}
                        response={response}
                        plan_reg_url={plan_reg_url}
                        lookup_url={lookup_url}
                        access_token={this.jwt.get_access_token()}
                        refresh_token={this.props.refresh_token}
                        state_choices={this.props.state_choices}
                    />
                </Fragment>
                }

            </Fragment>
        )
    }
}
