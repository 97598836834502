import React, {Component, Fragment} from 'react';
import { Row, Col, Button, Table } from "reactstrap";
import axios from 'axios';
import FieldInput from "../forms/field_input";
import linkState from 'alpaca.js/dist/util/link_state';
import ClaimDetail from "../claims/claim_detail";
import {currency, get_first_letter} from 'alpaca.js/dist/util/util';
import SidePanelWrapper from "../util/side_panel";
import LoadingButton from "alpaca.js/dist/util/loadingButton";


const PartsTable = (props) => {
    return (
        <Fragment>
            <h4>Parts</h4>
            <Table className="mb-1">
                <thead>
                <tr>
                    {/*<th>Available</th>*/}
                    <th>Part Description</th>
                    <th>Part #</th>
                    <th className="price-col">Price</th>
                    <th className="price-col">Freight</th>
                    <th className="price-col">Installation</th>
                    {/*<th> </th>*/}
                </tr>
                </thead>
                <tbody>
                    {props.children}
                </tbody>
            </Table>
        </Fragment>
    );
};

function sidePanelData(part_order) {

    let quote_request_link = 'N/A';
    if (part_order.quote_request_id) {
        let qr_id = part_order.quote_request_id;
        quote_request_link = (<a href={`/quotes/${qr_id}`} target="_blank">Quote #{qr_id}</a>);
    }
    let plan = part_order.claims[0].plan;

    return [
        [
            {label: "Ticket", value: `#${part_order.claims[0].ticket}`},
            {
                label: "Part Order Status",
                value: <span className={`status-${part_order.status}`}>{_.startCase(part_order.status)}</span>
            },
            {
                label: "Customer Name",
                value: <span>
                    <span className="avatar avatar-blank mr-2">{get_first_letter(part_order.customer_name || 'U')}</span>
                    {part_order.customer_name || '[Unknown]'}
                </span>
            },
            {label: "Quote Request", value: quote_request_link},
            {label: "Plan", value: plan.plan_number},
        ],
        [
            {label: "Total Authorized Amount", value: currency(part_order.authorized_amount)},
            {label: "Authorized Parts Cost", value: currency(part_order.authorized_part_amount) || "N/A"},
            {label: "Authorized Freight Cost", value: currency(part_order.authorized_delivery_amount) || "N/A"},
            {label: "Authorized Installation Cost", value: currency(part_order.authorized_installation_amount) || "N/A"},
        ],
    ];
}

export default class PartOrder extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            loading: true,
            part_order: null,
            errors: {},
            submitting: false,
            submitting_invoice: false,
        };
    }

    componentDidMount() {
        let component = this;

        axios.get(`/api/part-orders/${component.props.part_order_id}/`).then((response) => {
            component.setState({part_order: response.data, loading: false});
        });
    }

    canSubmit() {
        // retail and wholesale price for every quote request claim must be provided
        // any available parts must have time frame and price fields
        if (this.state.loading || this.inReview()) {
            return false;
        }
        let part_order = this.state.part_order;
        if (_.trim(part_order.order_number) !== "") {
            return true;
        }
        return false;
    }

    inReview() {
        return (!this.state.loading && this.state.part_order.date_submitted !== null);
    }

    submit(invoice=false) {
        // console.log('Submit button clicked');
        let po_id = this.props.part_order_id;
        let po = this.state.part_order;

        let request_data = {
            'part_order_response_notes': po.part_order_response_notes,
            'order_number': po.order_number
        };

        if(invoice) {
            this.setState({submitting_invoice: true});
        } else {
            this.setState({submitting: true});
        }

        axios.patch(`/api/part-orders/${po_id}/`, request_data).then((response) => {
            let success_url = $('.back-link').attr('href');
            if(invoice) {
                success_url = `/part-orders/${po_id}/invoice/`
            }
            window.location.pathname = success_url;
        });
    }

    renderRequestForm() {
        let component = this;

        // Renders the response notes and submit button for the quote request form
        /*jshint ignore:start*/
        return (
            <Fragment>
                <Row className="mt-4">
                    <Col className="col-sm-7">
                        <FieldInput
                            label="Order Number"
                            value={this.state.part_order.order_number}
                            onChange={linkState(this, "part_order", ["order_number"])}
                            readOnly={this.inReview()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-sm-7">
                        <FieldInput
                            label="Notes"
                            type="textarea"
                            value={this.state.part_order.part_order_response_notes}
                            onChange={linkState(this, "part_order", ["part_order_response_notes"])}
                            readOnly={this.inReview()}
                        />
                    </Col>
                    {!this.inReview() &&
                        <Col className="col-sm-5">
                            <LoadingButton color="primary" className="mt-4 mr-1" onClick={this.submit} disabled={!this.canSubmit()} loading={this.state.submitting}>
                                Submit
                            </LoadingButton>
                            <LoadingButton color="primary" className="mt-4" onClick={function(e){component.submit(e, true)}} disabled={!this.canSubmit()} loading={this.state.submitting_invoice}>
                                Submit &amp; Invoice
                            </LoadingButton>
                        </Col>
                    }
                </Row>
            </Fragment>
        );
        /*jshint ignore:end*/
    }

    renderParts(parts, rc_index) {
        return (
            <PartsTable>
                {parts.map((part, index) =>
                    <tr key={`part_${rc_index}_${index}`}>
                        <td>{part.description}</td>
                        <td>{part.part_number}</td>
                        <td>{currency(part.wholesale_price)}</td>
                        <td>{currency(part.delivery_price)}</td>
                        <td>{currency(part.installation_price)}</td>
                    </tr>
                )}

            </PartsTable>
        );
    }

    renderResolutionClaim(resolution_claim, rc_index) {
        let claim_id = resolution_claim.examination_claim.claim.id;
        let claim_index = _.findIndex(this.state.part_order.claims, c => c.id === claim_id);
        let claim = this.state.part_order.claims[claim_index];

        let parts = [];
        if (resolution_claim.extra_data && resolution_claim.extra_data.parts !== undefined) {
            parts = resolution_claim.extra_data.parts;
        }

        /*jshint ignore:start*/
        return (
            <Fragment key={`rc_${rc_index}`}>
                <ClaimDetail claim={claim}/>
                {parts.length > 0 && this.renderParts(parts, rc_index)}
            </Fragment>

        );
        /*jshint ignore:end*/
    }

    render() {
        let part_order = this.state.part_order;

        /*jshint ignore:start*/
        return (
            <main>
                {!this.state.loading &&
                    <form>
                        <SidePanelWrapper data={sidePanelData(part_order)} back_url="/part-orders/open/">
                            <div className="mb-4">
                                {part_order.resolution_claims.map((resolution_claim, index) =>
                                    this.renderResolutionClaim(resolution_claim, index)
                                )}
                            </div>

                            <dl>
                                <dt>Part order request notes</dt>
                                <dd>{part_order.part_order_request_notes}</dd>
                            </dl>

                            {this.renderRequestForm()}
                        </SidePanelWrapper>
                    </form>
                }
            </main>

        );
        /*jshint ignore:end*/
    }

}
