import React, { Component } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import { BootstrapDateInput } from "alpaca.js/dist/autoform/bootstrap";
import ReactPaginate from 'react-paginate';
import { Form } from 'informed';
import moment from "moment";
import {BootstrapCheckbox} from "alpaca.js/dist/autoform";

const ErrorRow = (props) => {
    let body = props.row.request_data.body || {};
    let token = props.row.token_info || {};
    let response = props.row.response || {};
    let cust = body.payment;
    cust = (cust && cust.shipping) || {};
    cust = (cust && cust.customer_info) || {};

    function editPlan(e) {
        e.preventDefault();
        if (props.addRegistration) {
            props.addRegistration(body, response, props.row._id);
        }
    }

    return (
        <tr key={props.index}>
            <td>
                <a href={"#"} onClick={editPlan}>{body.invoice_number || "n/a"}</a>
                <BootstrapCheckbox field={"deleteCheck"+props.row._id}
                                   onClick={function(e){
                                       props.pushToDelete(props.row._id, e.target.checked, e.target);
                                   }}
                />
            </td>
            <td className={"nowrap"}>{moment(props.row.time_stamp).format('MM/DD/YYYY h:mm A zz')}</td>
            <td className={"nowrap"}>{body.invoice_date}</td>
            <td>
                {cust.last_name}
            </td>
            <td>
                {cust.first_name}
            </td>
            <td>
                {token.sub}
            </td>
        </tr>
    )
};

const ErrorTable = (props) => {
    function pushToDelete(err, flag, target) {
        console.log("Pushing Error ID to Delete", err);
        console.log("State in props in ErrorTable", props.state);
        console.log("Target in push to delete", target);
        let toDel = props.errorFix.state.toDelete;
        let toUncheck = props.errorFix.state.checked;
        let ind = toDel.indexOf(err);
        if (flag) {
            if(ind !== -1) {
                console.log("this one is already added")
            }
            else {
                toDel.push(err);
                toUncheck.push(target);
            }
        }
        else {
            if(ind !== -1){
                toDel.splice(ind, 1);
            }
        }
        console.log("To Delete being submitted ", toDel);
        props.errorFix.setState({
            toDelete: toDel,
            checked: toUncheck
        });
    }

    return (
        <Table style={{height: "600px"}}>
            <thead>
            <tr>
                <th>Invoice #</th>
                <th>Timestamp</th>
                <th>Invoice<br/>Date</th>
                <th>Last Name</th>
                <th>First Name</th>
                <th>Submitted<br/>By</th>
            </tr>
            </thead>
            <tbody>
            {props.data.map((row, index) => (
                <ErrorRow key={index} row={row} index={index}
                          pushToDelete={pushToDelete}
                          addRegistration={props.addRegistration}/>
            ))}
            <tr>
                <td colSpan={6}>
                    <div className={"spinner-overlay " + (props.loading ? "active" : "inactive")}>
                        <i className="fa fa-spinner fa-spin fa-2x spin-loader"></i>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={6} className="right">
                    {props.table.props.pagination.count_pages ?
                        <ReactPaginate key={props.table.key}
                                       initialPage={props.table.props.pagination.page - 1}
                                       previousLabel={<i className="fas fa-chevron-left"/>}
                                       nextLabel={<i className="fas fa-chevron-right"/>}
                                       breakLabel={'...'}
                                       breakClassName={'break-me'}
                                       pageCount={props.table.props.pagination.count_pages}
                                       marginPagesDisplayed={Math.min(props.table.props.pagination.count_pages, 2)}
                                       pageRangeDisplayed={Math.min(props.table.props.pagination.count_pages, 5)}
                                       onPageChange={props.table.setPage}
                                       containerClassName={'pagination'}
                                       subContainerClassName={'pages pagination'}
                                       activeClassName={'active'}
                                       pageLinkClassName={"page-link"}
                                       breakLinkClassName={"page-link"}
                                       nextLinkClassName={"page-link"}
                                       previousLinkClassName={"page-link"}
                        /> : <div/>}
                </td>
            </tr>
            </tfoot>
        </Table>
    )
};

export class ErrorSearch extends Component {
    constructor(props) {
        super(props);

        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.setPage = this.setPage.bind(this);
        this.key = Date.now();
        this.state = {
            toDelete: [],
        }
    }

    nextPage() {
        this.setPage(this.props.current_page + 1);
    }

    prevPage() {
        this.setPage(this.props.current_page - 1);
    }

    setPage(page) {
        this.props.updatePage(page);
    }

    render() {
        return (
            <Form initialValues={this.props.initial} onChange={formState => this.props.updateDates(formState)}>
                {({formApi, formState}) => (
                    <Card>
                        <CardBody>
                            <CardTitle><h4>{this.props.title}</h4></CardTitle>
                            <Row>
                                <Col md="3" sm="6">
                                    <BootstrapDateInput dateFormat="YYYY-MM-DD" field="error_date"
                                                        label="From Date"/>
                                </Col>
                                <Col md="3" sm="6">
                                    <BootstrapDateInput dateFormat="YYYY-MM-DD" field="error_date2"
                                                        label="To Date"/>
                                </Col>
                                <Col md="3" sm="6">
                                    <Button color="primary" size="sm" className="mt-4" onClick={(e) => {
                                        this.props.byDate(e, formState.values.error_date, formState.values.error_date2);
                                    }}>
                                        Search by Date
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={"col-sm-2"}>
                                    <button className={"btn btn-secondary btn-xs text-danger"} onClick={() => {
                                        console.log("To Delete Errors in submitErrors", this.props.errorFix.state.toDelete);
                                        this.props.errorFix.clearErrors(this.props.errorFix.state.toDelete);
                                    }}>
                                        ❌ Clear Errors
                                    </button>
                                </Col>
                            </Row>
                            <ErrorTable data={this.props.data} table={this}
                                        addRegistration={this.props.addRegistration}
                                        loading={this.props.loading}
                                        errorFix={this.props.errorFix}>
                            </ErrorTable>
                        </CardBody>
                    </Card>
                )}
            </Form>
        );
    }
}
